import React, { useState, Fragment, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import CardModalVIPLS from '../CardModalVIPLS';

import 'swiper/scss';
import "swiper/css/effect-fade";

import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'
import Nftsf from '../../../assets/images/sf-nft/nftsf.png';
import HauntedHouse from '../../../assets/images/sf-nft/haunted-house.jpeg';
import { ReactComponent as RightArrow } from '../../../assets/right-arrow-svgrepo-com.svg'
import NftGif from '../../../assets/images/sf-nft/hub-halloween.gif';

import { useSelector } from 'react-redux';

const LiveAuctionVIPLS = ({ title, image }) => {
  const item = {
    img: [HauntedHouse],
    title: "NEAR Hub Haunted Halloween House",
    description: "The NFT San Francisco Conference is planning a series of sponsored afterparties. Come meet members of the NFT SF Conference in an VR Haunted Halloween House event on October 31st- November 4th on NearHub.",
    nameAuthor: "NFT SF CON & NearHUB",
    launch: (new Date(Date.UTC('2022', '03', '20', '16', '20', '0'))),
    selected: 1,
    prevSelected: 1,
    url: '/haunted-halloween-house',
    available: 500,
    sold: '-'
  }

  const nearWallet = useSelector(state => state.near.wallet);
  const nftContract = useSelector(state => state.near.nftContract);
  const [modalShow, setModalShow] = useState(false);
  const [slideTitle, setSlideTitle] = useState(title);
  const [qtySold, setQtySold] = useState('-');
  const [price, setPrice] = useState(0);
  const [tokens, setTokens] = useState([]);
  const { utils } = nearAPI;

  const getTokens = async () => {
    nftContract.get_minted_quantity().then((qty) => {
      setQtySold(qty); 
      if (qty > 1700) setPrice(5);
      else if (qty > 1200) setPrice(3);
      else if (qty > 700) setPrice(2);
      else if (qty > 200) setPrice(1);
      else setPrice(0);
    });
    if (nearWallet?.isSignedIn()) {
      nftContract.nft_tokens_for_owner({account_id: nearWallet.getAccountId()}).then((t) => { setTokens(t); });
    }
  }

  useEffect(() => {
    if (nftContract) {
      getTokens();
    }
  }, [nftContract, nearWallet]);

  const handlePurchase = async () => {
    const result = await nftContract.nft_mint(
      {},
      "75000000000000",
      utils.format.parseNearAmount(price.toString()) 
    );
    getTokens();
    setModalShow(false);
  }

  return (
    <Fragment>
      <section className="tf-section live-auctions" style={{ backgroundImage: 'url("/assets/back2.png")' }}>
      { tokens && tokens.length > 0 && tokens.map((nft, ind) => (
        <div key={'nft'+ind} className="themesflat-container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="swiper-container show-shadow carousel auctions">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="sc-card-product">
                        <h3 style={{textAlign: 'center', marginBottom: 20}}>Your Pass</h3>
                          <div className="card-media">        
                            <a href="https://nearhub.club/ZTHrs36/near-hub-vr-haunted-house">
                              <video autoPlay={true} muted={true} loop={true} src={nft.metadata.media} alt={nft.metadata.description} />
                            </a> 
                          </div>
                          <div className="card-title" style={{ color: '#ffffff' }}>
                            <h5><a href="https://nearhub.club/ZTHrs36/near-hub-vr-haunted-house">{nft.metadata.description}</a></h5>   
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="info">
                                <span>Owner</span>
                                <h6><a to="/authors-02">{nft.owner_id}</a> </h6>
                              </div>
                            </div>    
                            <div className="price" style={{}}>
                              <a href="https://nearhub.club/ZTHrs36/near-hub-vr-haunted-house" style={{ backgroundColor: 'var(--primary-color3)', color: '#fff', padding: '9px 20px', fontSize: 16, borderRadius: 13, marginBottom: -9, marginRight: 4 }}>
                                Visit Room
                              </a>
                            </div>                                                    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        ))}
        <div className="themesflat-container" style={tokens.length > 0 ? { display: 'none'} : {}}>
          <div className="row" style={{display:'none'}}>
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="heading-live-auctions">
                <h2 className="tf-title" style={{padding:0}}>{title}</h2>   
                <span style={{fontSize:16}}>{2000-qtySold} of 2000 available</span>                             
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">                            
              <div className="swiper-container show-shadow carousel auctions">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="sc-card-product">
                        { 
                          qtySold != 2000 &&
                          <div className="button-place-bid" style={{zIndex: 22}}>
                              <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Get Pass</span></button>
                          </div>
                        }
                        <div className="card-media">
                          <Swiper
                            spaceBetween={10}
                            effect={"fade"}
                            modules={[EffectFade, Autoplay]}              
                            loop
                            autoplay={{
                              delay: 1,
                              disableOnInteraction: false,
                              pauseOnMouseEnter: false
                            }}
                            speed= {3000}
                            style={{borderRadius: '5px'}}
                            onSlideChangeTransitionStart={(e) => {
                              switch(e.realIndex) {
                                case 0: setSlideTitle(title); break;                                                                    
                              }                                                                
                            }}
                          >
                            <SwiperSlide><img src={image} alt={title} /></SwiperSlide>                                                            
                          </Swiper>
                          { 
                            qtySold != 2000 &&
                            <div className="button-place-bid" style={{zIndex: 22}}>
                              <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Get Pass</span></button>
                            </div>
                          }
                        </div>

                        { 
                          qtySold != 2000 &&
                          <div className="button-place-bid" style={{zIndex: 22}}>
                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Get Pass</span></button>
                          </div>
                        }
                        <div className="card-title" style={{marginBottom:0}}>
                          <h5><a>{slideTitle}</a></h5>  
                        </div>
                        <div className="card-title">
                          <p>{item.description}</p>  
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar" style={{borderRadius:0}}>
                              <img src={Nftsf} alt="NFTSF" />
                            </div>
                            <div className="info">
                              <span>Creator</span>
                              <h6><a to="/authors-02">NFT-SF &amp; NearHUB</a> </h6>
                            </div>
                            </div>
                            <div className="price" style={{}}>                                                                        
                              <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginRight: 10, textAlign: 'right', justifyContent: 'right'}}>
                                { 
                                  qtySold == 2000 
                                  ? 'SOLD OUT'
                                  : <>
                                      {price == 0 && <span style={{fontSize: 28}}>FREE</span>}
                                      {price > 0 && <>{price} <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /> </>}
                                    </>
                                }
                              </h5>
                              <p style={{marginRight:10}}>{2000-qtySold} of 2000 available</p>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', fontSize: 16, textAlign: 'center', margin: '20px 0px', alignItems: 'center', gap: 15 }}>
                            <div>First 200 = FREE</div>
                            <RightArrow fill='#fff' width={'20px'} />
                            <div>Next 500 = 1 <NearType style={{display:'inline', fill: '#fff', height: 10, marginLeft: 1}} /></div>
                            <RightArrow fill='#fff' width={'20px'} />
                            <div>Next 500 = 2 <NearType style={{display:'inline', fill: '#fff', height: 10, marginLeft: 1}} /></div>
                            <RightArrow fill='#fff' width={'20px'} />
                            <div>Next 500 = 3 <NearType style={{display:'inline', fill: '#fff', height: 10, marginLeft: 1}} /></div>
                            <RightArrow fill='#fff' width={'20px'} />
                            <div>Next 300 = 5 <NearType style={{display:'inline', fill: '#fff', height: 10, marginLeft: 1}} /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>                
        </section>
        { tokens.length == 0 &&
          <CardModalVIPLS
            show={modalShow}
            onHide={() => setModalShow(false)}
            onPurchase={handlePurchase}     
            price={price}           
          />
        }
    </Fragment>
  );
}


export default LiveAuctionVIPLS;
