import React from 'react';

import NavHeader from '../components/header/NavHeader';
import Footer from '../components/footer/Footer';
import Main from '../components/layouts/home-5/Main';

const Home = () => {
    return (
        <div className='home-5'>
            <NavHeader />
            <Main />                        
            <Footer />
        </div>
    );
}

export default Home;
