import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import Countdown from "react-countdown";
import * as nearAPI from 'near-api-js';

import GridSystem from './GridSystem';
import Card from 'react-bootstrap/Card';

import CardModal from '../CardModal';

import 'swiper/scss';
import "swiper/css/effect-fade";

import NftGif from '../../../assets/images/sf-nft/hub-halloween.gif';
import HauntedHouse from '../../../assets/images/sf-nft/haunted-house.jpeg';
import Nftsf from '../../../assets/images/sf-nft/nftsf.png';

import { useSelector } from 'react-redux';

const LiveAuction = () => {
  const [data, setData] = useState(
    [
      {
        img: [HauntedHouse],
        title: "NEAR Hub Haunted Halloween House",
        description: "The NFT San Francisco Conference is planning a series of sponsored afterparties. Come meet members of the NFT SF Conference in an VR Haunted Halloween House event on October 31st- November 4th on NearHub. Click here to enter the Haunted House. https://nearhub.club/ZTHrs36/near-hub-vr-haunted-house",
        nameAuthor: "NFT SF CON & NearHUB",
        launch: (new Date(Date.UTC('2022', '03', '20', '16', '20', '0'))),
        selected: 1,
        prevSelected: 1,
        url: '/haunted-halloween-house',
        available: 2000,
        sold: '-'
      },      
    ]
  )

  const [now] = useState(Date.now());
  const [modalShow, setModalShow] = useState(false);

  const nearWallet = useSelector(state => state.near.wallet);
  const nftContractWL   = useSelector(state => state.near.nftContract);    

  useEffect(() => {
      setInterval(() => {
          const newData = data.map(ele => {
              if(typeof ele.selected == 'number') {
                  ele.prevSelected = ele.selected;
                  ele.selected++;
                  if (ele.selected > ele.img.length) ele.selected = 1;
              } else {
                  ele.prevSelected = [...ele.selected];
                  ele.selected = ele.selected.map((ele2, ind2) => Math.floor(Math.random() * ele.img[ind2].length) + 1);
              }
              return ele;
          });

          setData(newData);
      }, 5000)
  }, []);

  useEffect(() => {
      if (nftContractWL) {
          nftContractWL.get_user_minted_quantity().then((qty) => {
              data[1].sold = qty;
              setData(data);                
          });            
          nftContractWL.get_free_minted_quantity().then((qty) => {
              data[0].sold = qty;
              setData(data);                
          });            
      }
  }, [nftContractWL, nearWallet]);

  return (
    <Fragment>
      <section className="tf-section live-auctions" style={styles.container}>
        <div style={{maxWidth:600, margin: '0 auto'}}>
          <GridSystem colCount={1} md={12} title="Admit 1: Haunted house by NEAR Hub & NFT SF">          
            {
              data.map((item, index) => (                                       
                <div key={'item'+index} className="swiper-container show-shadow">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="slider-item">
                        <div className="sc-card-product">
                          <div className="card-media">
                            <Link to={item.url}>
                              {
                                item.img.map((img, ind) => {
                                  if (typeof img == 'string') {
                                    return <img  key={'img'+index+ind} src={(item.selected == (ind+1) || item.prevSelected == (ind+1) || ind == 0) ? img : NftGif} alt={item.title} className={item.selected == (ind+1) ? 'img selected' : 'img'} />
                                  } else {
                                      return (
                                        img.map((img2, ind2) => 
                                          <img  
                                            key={'img'+index+ind+ind2} 
                                            src={(item.selected[ind] == (ind2+1) || item.prevSelected[ind] == (ind2+1) || ind == 0) ? img2 : NftGif} 
                                            alt={item.title} 
                                            className={item.selected[ind] == (ind2+1) ? 'img selected' : 'img'} 
                                          />
                                        )
                                      );
                                  }                                                                        
                                })
                              }                                                                                                                                    
                            </Link>
                          </div>
                          <div className="card-title" style={{ color: '#fff' }}>
                            <h5><Link to={item.url}>{item.title}</Link></h5>                                                                    
                          </div>
                          <div style={{ color: '#fff', fontSize: 12, marginBottom: 10 }}>
                            <p>{item.description}</p>                                                                    
                          </div>
                          <div className="card-title">
                            <h5><Link to='https://nftsanfrancisco.xyz/' target={'_blank'}>More Info...</Link></h5>                                                                    
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="avatar" style={{padding: 6 }}>
                                <img src={Nftsf} alt="NFT SF" />
                              </div>
                              <div className="info">
                                <span>Creator</span>
                                <h6>{item.nameAuthor}</h6>
                              </div>
                            </div>
                            {/* <div className="card-title">
                              <h5><Link to='https://nftsanfrancisco.xyz/' target={'_blank'}>Visit NFT-SF website</Link></h5>                                                                    
                            </div>        */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            } 
          </GridSystem>                        
        </div>
      </section>
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Fragment>
  );
}

const styles = {
  container: {
    backgroundImage: 'url("/assets/back2.png")',
  },
};


export default LiveAuction;
