import React from 'react';

import NavHeader from '../components/header/NavHeader';
import Footer from '../components/footer/Footer';
import Item from '../components/layouts/home-5/Item';


const HomeVIPLS = ({ title, image }) => {
  return (
    <div className='home-5'>
      <NavHeader />
      <Item title={title} image={image} />            
      <Footer />
    </div>
  );
}

export default HomeVIPLS;
