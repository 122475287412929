import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

import { ReactComponent as NearType } from '../../assets/images/icon/near_type.svg'

import NEAR from '../../constants/near';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CardModalVIPLS = (props) => {    
    const nearWallet = useSelector(state => state.near.wallet);
    const [isMinting, setIsMinting] = useState(false);

    const login = () => {
        nearWallet?.requestSignIn(
            NEAR.NFT_CONTRACT_NAME,
           'NFT SF CON & NearHUB'
        );
    }

    return (

        <Modal
        show={props.show}
        onHide={props.onHide}
    >
        <Modal.Header closeButton></Modal.Header>

        <div className="modal-body space-y-20 pd-40">
            <h3>Get Pass</h3>
            
            <div className="price" style={{}}>                                                                        
                <h5 style={{fontSize: 40, display: 'flex', alignItems: 'center', marginBottom: 13, justifyContent: 'center'}}>
                    { props.price == 0 && <>FREE</> }
                    { props.price > 0 && <>{props.price} <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /></> }
                </h5>
            </div>
            { nearWallet?.isSignedIn() && nearWallet.getAccountId() &&
              <a 
                onClick={() => {
                    if (!isMinting) {
                        setIsMinting(true);
                        props.onPurchase();
                    }
                }} 
                className={"btn btn-primary"} 
                style={isMinting ? {cursor: 'not-allowed', opacity: 0.5} : {}}
                data-toggle="modal" 
                data-target="#popup_bid_success" 
                data-dismiss="modal" aria-label="Purchase NFT">Get Pass</a>
            }
            { nearWallet?.isSignedIn() !== true &&
              <a onClick={login} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Connect Wallet">Connect Wallet</a>
            }
        </div>
    </Modal>

    );
};

export default CardModalVIPLS;
