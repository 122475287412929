import HomeVIPLS from "./HomeVIPLS";
import Home from "./Home";

import NftGif from '../assets/images/sf-nft/hub-halloween.gif';
import HauntedHouse from '../assets/images/sf-nft/haunted-house.jpeg';

const routes = [
  {
    path: '/',
    component: <Home />
  },
  {
    path: '/haunted-halloween-house',
    component: <HomeVIPLS title="Near Hub Haunted House" image={HauntedHouse} />
  }
]

export default routes;
