import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const GridSystem = ({ colCount, children, md, title }) => {
  let rowCount = Math.floor(children.length / colCount) + 1;
  let index = 0;

  const buildGrid = () => {
    return renderRows();
  };

  // return a row with 3 columns in it
  const renderRows = () => {
    let rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <Row key={i} style={styles.row}>
          {renderCols()}
        </Row>
      );
    }
    return rows;
  };

  // return a column with 1 card in it
  const renderCols = () => {
    let cols = [];
    for (let i = 0; i < colCount; i++) {
      cols.push(
        <Col key={i} md={md} className='Col'>
          {children[index]}
        </Col>
      );
      index++;
    }
    return cols;
  };

  return (
    <div style={styles.container}>
      <p style={styles.gridTitle}>{title}</p>
      {buildGrid()}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'transparent',
    margin: '0 10px'
  },
  row: {
    marginTop: '1rem',
  },
  gridTitle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '3rem',
    marginBottom: '2rem',
  },
};

export default GridSystem;